import { mapGetters } from "vuex";
import { sendMessageToParent } from "~/builder/builderUtils";
import { BUILDER_STOREFRONT_EVENTS } from "~/builder/builderConfig";

export default {
  computed: {
    ...mapGetters({
      isEditMode: "general/getIsEditMode",
    }),
  },
  mounted() {
    if (this.isEditMode) {
      if (this.pageData) {
        sendMessageToParent({
          event: BUILDER_STOREFRONT_EVENTS.VIEW_PAGE,
          data: {
            id: this.pageData.id,
            slug: this.pageData.slug,
          },
        });
      }
    }
  },
};
